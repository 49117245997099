//  商品列表
import { mapState } from "vuex";
import RetailProductItem from "@/components/retail-order/retail-product-item/index.vue";
import { getRetailProducts } from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";
import { Toast } from "vant";

export default {
  name: "retail-order-dispatch",
  components: { RetailProductItem },

  data() {
    return {
      products: [],
      addressInfo: {
        userName: '',
        phone: ''
      }
    };
  },

  computed: {
    ...mapState({
      logo: (state) =>
        state.permission.permission["retail-order"] &&
        state.permission.permission["retail-order"].logo,
    }),

    //  选中下单的商品
    selectedProducts() {
      return this.products.filter((p) => p.quantity > 0);
    },

    //  已选商品件数
    sumCount() {
      return this.selectedProducts.reduce((sum, p) => {
        return sum + Number(p.quantity);
      }, 0);
    },
  },

  methods: {
    //  跳转到发货页面
    toSend() {
      if (!this.addressInfo.userName) {
        Toast("请填写收货人名称！");
        return;
      }
      if (!this.addressInfo.phone) {
        Toast("请填写收货人手机号！");
        return;
      } else {
        if (!(/^1[3456789]\d{9}$/.test(this.addressInfo.phone))) {
          Toast("手机号码有误，请重填");  
          return;
        }
      }
      if (!this.selectedProducts.length) {
        Toast("至少选中一个商品才发货～");
        return;
      }
      //  将选中的商品信息保存到vuex中
      this.$store.commit("retail/setField", {
        addressInfo: this.addressInfo,
        productList: this.selectedProducts,
        type: "1",
      });
      this.$router.push({ name: "retail-dispatch-terminal" });
    },
  },
  
  created() {
    //  清除预订单
    this.$store.dispatch("retail/reset");
    getRetailProducts({ token: getToken() })
      .then((res) => {
        this.products = res || [];
      })
      .catch((err) => {
        console.error(err);
        this.products = [];
      });
  },
};
